
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ApiBase,
  ApiFulfillmentOrders,
  ApiStock,
  ApiWarehouses,
} from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "warehouse-management-stock-out-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: route.params.id,
      stock_out_id: null,
      stock_out_time: null,
      warehouse_id: "",
      status: "",
      stock_out_type: "",
      sku_identifier: "",
      target_quantity: "",
      actual_quantity: "",
      exception_status: "",
      next_shipment: "",
      next_shipment_tracking_no: "",
      related_sales_order: "",
      related_fulfillment_order: "",
      __show: {
        status: "",
      },
    });
    const options = ref({
      warehouse: [],
      status: [],
      // status: new Map([]),
      stock_out_type: [
        { label: t("stockOut.stockOutTypeNormal"), value: "1" },
        { label: t("stockOut.stockOutTypeAbnormal"), value: "2" },
      ],
    });

    const rules = ref({});

    const getShowInfo = async () => {
      const { data } = await ApiStock.showStockOut({ id: route.params.id });
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["stock_out_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.stock_out_status.items;
        // data.data.stock_out_status.items.map((item, index) => {
        //   options.value.status.set(item.value, item.label);
        // });
      }
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getWarehouseData(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWarehouses.updateWarehouse(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const statusClass = (val) => {
      let statusClass = "";
      switch (val) {
        case "10": // 预订出仓
          statusClass = "badge-light-primary";
          break;
        case "20": // 临时出仓
          statusClass = "badge-light-info";
          break;
        case "30": // 列队进入
        case "40": // 出仓进行中
          statusClass = "badge-light-warning";
          break;
        case "50": // 出仓完毕
          statusClass = "badge-light-success";
          break;
        case "60": // 取消
          statusClass = "badge-light-dark";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const backToList = () => {
      commonBackToList(router, "/warehouse-management/stock-out");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      getFromInfo,
      submit,
      statusClass,
      backToList,
    };
  },
});
